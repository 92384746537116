@use '@angular/material' as mat;
@import '@angular/material/theming';

// custom app-theme variables
$log-fs: 13px;

$perfect-scroll-bar-color: #d9d9d9;
$bg-color-white: #fff;
$bg-color-blue: #2ea2f8;
$bg-color-input-text: #f4f8f9;
$bg-color-filter-reset: #4da1ff;
$log-back-color: #e8ecef;
$log-item-text-color: #001d40;
$font-color-grey: #808fa3;
$font-color-dark-blue: #48688c;
$font-color-blue: #1991eb;
$font-color-white: #fff;
$font-color-passive: #b3b4bd;
$font-color-active: #48688c;
$font-color-filter-placeholder: #001d40;
$font-color-filter-reset: #4da1ff;
$font-color-input-text: #b3b4bd;
$border-color-grey: #e8ecef;
$border-color-cb: #d9d9d9;
$border-color-blue: #2ea2f8;
$border-color-input-active: #4da1ff;
$border-color-active: #4da1ff;

$button-bg-blue: #1991eb;
$button-bg-blue-active: #2ea2f8;
$button-bg-gray: #48688c;
$button-bg-blue-selection: #1ec1e1;
$button-bg-blue-hover: #00335b;

$dropdown-bg: #f4f8f9;
$dropdown-bg-active: #f4f8f9;
$dropdown-panel-bg: #f5f8fa;
$dropdown-selected-color: #4da1ff;

$datepicker-color-blue: #4da1ffcc;
$datepicker-font-color: #606166;

$font-color-error: #f03e3e;
$app-background-card: #e8ecef;
$app-background: #ccd3e3;
$app-background-blue: #2581c5;

$attention-color: orange;

/* rewrite main theme colors */

$app-primary: mat.define-palette(mat.$blue-palette);
$app-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn
    ),
    typography: mat.define-typography-config(),
    density: 0
  )
);
//$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

/* rewrite main theme colors */

$border-radius: 8px;
$min-width: 1375px;
$header-width: 50px;
$font-family: GraphikLCG-Regular;
$font-family-sans-serif: GraphikLCG, sans-serif;
