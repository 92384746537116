@import 'src/theme/theme-variables';
@import 'src/theme/additional-matherial-styles';

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: GraphikLCG-Regular, sans-serif;
}

ul,
p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  outline: none;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

button {
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
}

/* disable shadow on header begin */

.mat-elevation-z6 {
  box-shadow: none;
}

/* disable shadow on header end */

/* filters scroll wrapper style begin */

.ps-wrapper {
  $scroll-width: 3px;
  $scroll-indent: 5px;
  $scroll-indent-hover: 28px;
  $scroll-border-radius: 1.5px;

  max-height: 100vh;

  @mixin ps__thumb_common {
    border-radius: $scroll-border-radius;
    background-color: $perfect-scroll-bar-color;
  }

  .ps__thumb-y {
    @include ps__thumb_common;

    width: $scroll-width;
    right: $scroll-indent;
  }

  .ps__thumb-x {
    @include ps__thumb_common;

    height: $scroll-width;
    bottom: $scroll-indent;
  }

  .ps__rail-y {
    box-sizing: border-box;
    width: $scroll-width;
    opacity: 1;
    z-index: 2;

    &.ps--clicking {
      width: $scroll-width;

      .ps__thumb-y {
        width: $scroll-width;
        right: $scroll-indent;
      }
    }

    &:hover {
      width: $scroll-width;
      right: $scroll-indent-hover;

      & > .ps__thumb-y {
        width: $scroll-width;
      }
    }

    &:focus {
      & > .ps__thumb-y {
        width: $scroll-width;
      }
    }
  }

  .ps__rail-x {
    box-sizing: border-box;
    height: $scroll-width;
    opacity: 1;

    &.ps--clicking {
      height: $scroll-width;

      .ps__thumb-x {
        height: $scroll-width;
        right: $scroll-indent;
      }
    }

    &:hover {
      height: $scroll-width;
      bottom: $scroll-indent-hover;

      & > .ps__thumb-x {
        height: $scroll-width;
      }
    }

    &:focus {
      & > .ps__thumb-x {
        height: $scroll-width;
      }
    }
  }

  .ps {
    width: 100%;

    &.ps--active-y {
      .ps__rail-y {
        width: $scroll-width;
        background-color: transparent;
      }
    }

    &.ps--active-x {
      .ps__rail-x {
        height: $scroll-width;
        background-color: transparent;
      }
    }
  }
}

/* logs scroll wrapper style end */

/* style for logs filters component begin */

.mat-form-field-wrapper {
  width: 100%;
}

.mat-form-field-prefix {
  top: 5px;
}

.form-datepicker-field {
  .mat-form-field-suffix {
    order: 1;
  }

  .mat-form-field-infix {
    order: 2;
    height: 50px;
  }
}

.mat-checkbox {
  margin-bottom: 4px;

  &.dropdown-checkbox {
    .mat-checkbox-label {
      width: 125px;
    }
  }
}

.mat-checkbox-inner-container {
  margin-right: 6px;
}

.mat-checkbox-background,
.mat-checkbox-frame {
  border-color: $border-color-cb;
  border-radius: 3px;
}

.mat-radio-button {
  .cdk-focused .mat-ripple {
    background-color: transparent !important;
  }
}

.mat-radio-outer-circle {
  border-color: $border-color-cb;
}

.mat-checkbox-label {
  font-weight: 100;
  font-size: $log-fs - 3;
  color: $log-item-text-color;
  font-family: GraphikLCG-Regular;
  text-transform: capitalize;
}

.mat-radio-label {
  font-weight: 100;
  font-size: $log-fs - 3;
  color: $log-item-text-color;
  font-family: GraphikLCG-Regular;
  text-transform: capitalize;
}

/* style for logs filters component end */

// style for material components (header)

.tooltip-container.bar::before {
  position: relative;
  visibility: hidden;
}

.tooltip-container {
  &::before {
    content: '';
    position: absolute;
    background-image: url('/assets/icons/chart/tooltip-triangle.svg');
    height: 8px;
    width: 7px;
    left: -8px;
    top: calc(50% - 3px);
    transform: rotate(-90deg);
  }

  &.bar {
    text-align: center;
  }

  .title {
    font-size: 16px;
    margin-bottom: 22px;
  }

  .dot-info {
    border-radius: 5px;
    background-color: #f4f8f922;
    color: #fff;
    font-size: 12px;
    padding: 8px 12px;
    font-family: GraphikLCG-Regular, sans-serif;
    margin-bottom: 10px;
    position: relative;

    .value-count {
      font-size: 25px;
      line-height: 25px;
      display: block;
    }

    .logo {
      height: 18px;
      width: 18px;
      background-color: #fff;
      position: absolute;
      right: 10px;
      bottom: 7px;

      &.list-icon {
        mask-image: url('/assets/icons/chart/list.svg');
        mask-repeat: no-repeat;
      }
    }

    .arrow {
      text-align: center;
      height: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 73px;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        background-color: #fff;
        height: 8px;
        width: 16px;
        margin-right: 7px;
      }

      &.down {
        background-color: #e4011d;

        img {
          mask: url('/assets/icons/chart/tooltip-arrow-up.svg') no-repeat;
        }
      }

      &.up {
        background-color: #7ed321;

        img {
          mask: url('/assets/icons/chart/tooltip-arrow-down.svg') no-repeat;
        }
      }
    }
  }

  .tooltip-values {
    display: flex;
    width: 100%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 0;
      border-bottom: 2px solid #fff5;
      top: -12px;
      left: -16px;
      width: calc(100% + 32px);
    }

    .dot-info {
      width: 50%;
    }

    .dot-info:first-of-type {
      margin-right: 10px;
    }
  }

  .copy {
    width: 100%;
    padding: 9px 16px;
    border-radius: 5px;
    color: $font-color-active;
    background-color: #fff;
    outline: none;
  }
}

.report-chart {
  .tooltip-container {
    box-shadow: 0 10px 25px -4px rgba(0, 0, 0, 0.4);
    padding: 6px 12px;

    .report-value {
      font-family: GraphikLCG;
      font-size: 13px;
    }
  }
}

.ps-wrapper-content {
  perfect-scrollbar {
    $scroll-indent-active: 246px !important;

    & > .ps.ps--active-y > .ps__rail-y {
      right: $scroll-indent-active;
    }

    & > .ps.ps--active-x > .ps__rail-x {
      bottom: $scroll-indent-active;
    }
  }
}

.ps-content {
  height: 100%;

  .mat-elevation-z8 {
    .table-header {
      z-index: 1;
    }

    .ps-wrapper.monitor-table-background {
      overflow: visible;
    }
  }
}

.ps-wrapper.monitor-table-background.logs {
  background-color: #fff;
  min-height: 90px;
  overflow: visible;

  .ps-content {
    height: fit-content;
  }
}

.ngxp__container {
  // ngx-popper container
  max-width: 400px;
  min-width: 0;
  border: none !important;
  box-shadow: none !important;
  z-index: 101;
  max-height: 200px;

  &::before {
    z-index: 1;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: none;
    max-height: 200px;
    box-shadow: 0 10px 25px -4px rgba(0, 0, 0, 0.4);
    background-color: rgba(113, 127, 145, 0.99);
    border-radius: 5px;
  }

  .ngxp__inner {
    display: flex;
    flex-direction: column;

    .popper-text {
      font-family: GraphikLCG-Regular, serif;
      color: white;
      position: relative;
      z-index: 2;
      word-wrap: break-word;
      font-size: 13px;
      white-space: normal !important;

      &.title {
        font-family: GraphikLCG, serif;
        margin-bottom: 2px;
      }

      &.limited {
        max-width: 140px;
        text-align: left;
      }

      &.content {
        font-size: 13px;
        font-family: GraphikLCG-Regular, sans-serif;
        line-height: 1.38;
      }
    }

    .popper-text-button {
      margin: 15px 0 0 0;
      font-family: GraphikLCG-Regular, serif;
      color: white;
      position: relative;
      z-index: 2;
      word-wrap: break-word;
      font-size: 13px;
      text-align: center;
      width: 100%;
      white-space: normal !important;
    }
  }

  .ngxp__arrow {
    box-shadow: 0 10px 25px -4px rgba(0, 0, 0, 0.4);
    color: rgba(113, 127, 145, 0.99);
    z-index: 1000;
  }

  .ngxp__bufferButton {
    position: relative;
    z-index: 1;
    color: #4da1ff;
    cursor: pointer;
    font-size: 12px;
    font-family: GraphikLCG-Regular, serif;
  }
}

.in-focus .search-icon {
  color: $border-color-active !important;
}

.sorted {
  cursor: pointer;

  .sort-arrow {
    margin-left: 3px;
    transition: transform 0.4s;
    visibility: hidden;

    &.reverse {
      transform: rotate(180deg);
    }

    &.active {
      visibility: visible;
    }
  }

  &:hover .sort-arrow:not(.active) {
    visibility: visible;
    transform: rotate(180deg);
    transition: transform 0s;
  }
}

.table-footer {
  height: 50px;
  min-height: 50px;
  padding: 0 20px;
  background-color: #eff4f6;
  color: $font-color-dark-blue;
  font-size: 12px;
  border-radius: 0 0 5px 5px;

  &.logs-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .info span:first-of-type {
    opacity: 0.5;
    margin-right: 15px;
  }

  .watch {
    & > span {
      margin-right: 14px;
    }

    .mat-form-field {
      width: 64px;

      .mat-form-field-flex {
        min-height: 40px;
        height: fit-content;
        display: flex;
        align-items: center;
      }
    }
  }

  .redirect {
    display: flex;
    align-items: center;
  }

  .redirect input {
    height: 30px;
    width: 50px;
    border-radius: 3px;
    border: 1px solid transparent;
    margin-left: 20px;
    padding: 0 12px;
    outline: none;
    -moz-appearance: textfield;

    &:focus {
      border: 1px solid #4da1ff;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $font-color-passive;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $font-color-passive;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $font-color-passive;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: $font-color-passive;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

// chart options

.chart-type {
  position: absolute;
  right: 25px;
  bottom: -14px;

  .logo {
    background-color: $font-color-passive;
    width: 24px;
    height: 24px;
    margin-right: 15px;
    display: inline-flex;
    cursor: pointer;

    &.line-type-chart {
      mask-repeat: no-repeat;
      mask-image: url('/assets/icons/chart/line-type.svg');
    }

    &.step-type-chart {
      mask-repeat: no-repeat;
      mask-image: url('/assets/icons/chart/step-type.svg');
    }

    &.dotted-type-chart {
      mask-repeat: no-repeat;
      mask-image: url('/assets/icons/chart/dotted-type.svg');
    }

    &.bar-type-chart {
      mask-repeat: no-repeat;
      mask-image: url('/assets/icons/chart/bar-type.svg');
      margin: 0 0 0 15px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        height: 24px;
        border-left: 2px solid $app-background-card;
        top: 0;
        left: -16px;
      }
    }

    &.selected {
      background-color: $border-color-blue;
    }
  }
}

.chart-tools {
  width: 560px;

  .change-interval {
    margin: 10px 10px 10px 20px;
  }

  .scroll-interval {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 260px;
    background-color: #f4f8f9;
    border-radius: 5px;
    margin: 10px 20px 10px 0;
    font-size: 13px;
    color: #606166;

    .arrow-left,
    .arrow-right {
      padding: 0 10px;
      cursor: pointer;
    }

    .arrow-left {
      transform: rotate(90deg);
    }

    .arrow-right {
      transform: rotate(-90deg);
    }
  }
}

.change-interval {
  background-color: rgba(233, 239, 244, 0.48);
  height: 35px;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 7px;

  span {
    height: 25px;
    line-height: 25px;
    border-radius: 5px;
    font-family: GraphikLCG-Regular, sans-serif;
    font-size: 13px;
    text-align: center;
    color: $datepicker-font-color;
    width: 80px;
    cursor: pointer;
    margin: 0 2px;

    &:first-child {
      margin: 0 2px 0 0;
    }

    &:last-child {
      margin: 0 0 0 2px;
    }

    &:hover {
      background-color: #e9eff4;
    }

    &.selected {
      background-color: #fff;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
      color: $dropdown-selected-color;
    }
  }
}

.report-buttons app-export-button .button {
  color: #48688c;
}

.filter-tags .mat-standard-chip.mat-chip-with-trailing-icon {
  .mat-chip-remove {
    opacity: 1;

    img {
      width: 18px;
    }
  }
}

.mat-drawer-content.mat-sidenav-content {
  overflow: hidden;
}

.error-value {
  color: $font-color-error;
}

.mat-snack-bar-container {
  background-color: #fff;
  color: #48688c;
  border-radius: 8px;
  box-shadow: 0 8px 23px 0 rgba(0, 0, 0, 0.2);

  &.success-message {
    background-color: #12b886;
    color: #fff;
  }

  &.error-message {
    background-color: #ee5253;
    color: #fff;
  }
}

.disabled {
  pointer-events: none;
}

.ps__rail-x {
  z-index: 1 !important;
}

app-export-button span {
  font-family: $font-family-sans-serif;
}

.cellspacing-table {
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    padding: 0;
  }
}

.mat-menu-panel {
  max-width: 500px !important;
}

.cancelable-dialog {
  mat-dialog-container {
    position: relative !important;
  }
}

ngx-mat-select-search {
  .mat-select-search-input {
    color: $font-color-dark-blue;
  }

  .mat-select-search-clear {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    margin: 3px 8px 0;

    .mat-button-wrapper {
      display: flex;
      height: auto;

      mat-icon {
        color: $font-color-filter-placeholder;
        font-size: 14px;

        &:hover {
          color: $button-bg-blue;
        }
      }
    }
  }
}

.mat-option-multiple {
  mat-pseudo-checkbox {
    opacity: 0.6;
  }

  &.mat-selected {
    .mat-option-text {
      color: $button-bg-blue-active;
    }
  }
}

.tabs-dropdown-option,
.tree-search-option {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 14px !important;
}

.display-none {
  display: none !important;
}

.chart-legend {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  width: 100%;

  li {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    outline: 0;

    &:not(:last-child) {
      margin-bottom: 2px;
    }

    &:hover span {
      text-decoration: underline;
    }

    span {
      font: 400 14px $font-family;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgba(0, 0, 0, 0.87);
      opacity: 0.9;
    }

    &::before {
      content: '';
      display: block;
      width: 32px;
      height: 16px;
      margin-right: 4px;
      background-color: currentcolor;
      flex-shrink: 0;
    }
  }
}
