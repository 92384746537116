@import 'src/theme/theme-variables';

.mat-icon-button.cdk-focused.cdk-program-focused .mat-button-focus-overlay {
  opacity: 0 !important;
}

.mat-radio-button {
  .mat-radio-label {
    .mat-radio-container {
      height: 18px !important;
      width: 18px !important;

      .mat-radio-outer-circle {
        height: 18px !important;
        width: 18px !important;
      }

      .mat-radio-inner-circle {
        height: 18px !important;
        width: 18px !important;
      }
    }
  }

  // hide focus if radio is checked
  &.cdk-focused.mat-radio-checked {
    .mat-radio-ripple.mat-ripple {
      opacity: 0;
      transition: opacity 0.35s;
    }
  }
}

.custom-dropdown {
  .mat-form-field-underline {
    display: none;
  }

  &.mat-form-field .mat-form-field-infix {
    border: none;
    background-color: $dropdown-bg;
    padding: 9px 12px;
    border-radius: 5px;

    .mat-select-value-text {
      font-family: GraphikLCG-Regular, sans-serif;
      font-size: 12px;
      color: $font-color-active;
    }
  }

  .mat-form-field-flex:hover {
    .mat-form-field-infix {
      background-color: $dropdown-bg-active;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 5px;
  }
}

.custom-dropdown.mat-select-panel {
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: $dropdown-panel-bg;

  .mat-option {
    font-family: GraphikLCG-Regular, sans-serif;
    font-size: 12px;
    color: $font-color-active;

    &.mat-selected {
      color: $dropdown-selected-color;
      background: none;
    }
  }
}

.count-dropdown.mat-form-field {
  width: 60px;

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;

    .mat-form-field-infix {
      background-color: #fff;
      padding: 9px 5px 9px 9px;
    }
  }
}

// mat-radio-button
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-label .mat-radio-container {
  .mat-radio-outer-circle {
    border-color: #2fa2f8;
    background-color: #2fa2f8;
  }

  .mat-radio-inner-circle {
    background-color: #fff;
    height: 12px !important;
    width: 12px !important;
    left: 3px;
    top: 3px;
  }
}

.values-types .radio-filter .mat-radio-label-content,
.total .group-by .mat-radio-label-content {
  font-size: 12px;
  color: #48688c;
  font-family: GraphikLCG-Regular, sans-serif;
  display: flex;
  align-items: center;
}

.total .group-by .mat-radio-label-content {
  color: #001d40;
}

// mat-checkbox
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #2fa2f8;
  border-radius: 4px;

  .mat-checkbox-checkmark {
    top: 6%;
    left: 5%;
    width: 90%;

    .mat-checkbox-checkmark-path {
      stroke-width: 3.5px;
    }
  }
}

// mat-button for search events
.apply.mat-button {
  &:hover {
    color: rgba(255, 255, 255, 0.7);

    .mat-button-focus-overlay {
      opacity: 0 !important;
    }
  }

  &:active {
    box-shadow: none;
  }
}

// mat-option
.mat-option {
  font-family: GraphikLCG-Regular, sans-serif;
  color: #48688c;
}

.mdc-text-field--filled,
.mdc-text-field--filled:hover {
  background: transparent !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  height: 40px;
  min-height: 40px;
}

.mat-mdc-text-field-wrapper {
  min-height: 40px;
}

.mat-mdc-form-field-flex {
  height: 40px;
}

.mat-mdc-select-arrow-wrapper {
  height: 40px !important;
}

.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: initial !important;
}

*[matformfieldlineripple] {
  display: none !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 50% !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-136%) scale(0.75) !important;
  font-size: 12px !important;
}

.cdk-overlay-pane {
  width: auto !important;
}

.mat-mdc-select {
  font-size: 13px!important;
  line-height: 18px;
}

.mat-mdc-dialog-surface {
  padding: 20px 0;
}
