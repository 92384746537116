/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
@use '@angular/material' as mat;

// Theme variables, must be included before the libraries to allow overriding defaults
@import 'theme/theme-variables';

// 3rd party libraries
@import '~material-design-icons-iconfont/dist/fonts/material-icons.css';
@import '~@circlon/angular-tree-component/css/angular-tree-component.css';

// Angular Material custom theme
// The mixins below must be included once so we separated them from the variables
@import '@angular/material/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`

@include mat.all-component-typographies();
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Theme customization
@import 'theme/theme';

//Custom styles for Matherial
@import 'theme/additional-matherial-styles';

// Custom fonts
@import 'theme/fonts';
