/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

@font-face {
  font-family: GraphikLCG;
  src: url('/assets/fonts/Graphik_LCG-Medium.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: GraphikLCG-Regular;
  src: url('/assets/fonts/Graphik_LCG-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MaterialIcons-Regular;
  font-style: normal;
  font-weight: 400;
  src: local('MaterialIcons-Regular'), url('/assets/fonts/MaterialIcons-Regular.ttf') format('ttf');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
